// Create a new file, e.g., ChatModal.js

import React, { useState, useEffect } from "react";
import { Input, List, Modal, Row, Skeleton, Avatar } from "antd";
import { ListEmployeesRegis } from "utility/action";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { listChats } from "graphql/customQuery";
import { createChat, createMessage, updateChat } from "graphql/mutations";
import { useSelector } from "react-redux";
import { ConsoleExperience } from "amazon-quicksight-embedding-sdk";

const EmployeeListModal = ({ open, setOpen, updateUserInfo }) => {
  const navigate = useNavigate();
  const [initLoading, setInitLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const userDataAdmin = useSelector((state) => state.userReducer?.userData);
  console.log("userdata", userDataAdmin);
  const fetchEmp = async () => {
    try {
      setInitLoading(true);
      const filter = {
        lastLogin: { attributeExists: true },
      };
      const response = await ListEmployeesRegis(filter);
      const filteredResponse = response.filter(
        (user) => user.userType !== "SUPERADMIN"
      );

      const sortedResponse = filteredResponse.sort((a, b) => {
        const firstNameA = (a.profile?.firstName || "").toLowerCase();
        const firstNameB = (b.profile?.firstName || "").toLowerCase();
        return firstNameA.localeCompare(firstNameB);
      });

      setUserList(sortedResponse);
    } catch (error) {
      console.log(error);
    } finally {
      setInitLoading(false);
    }
  };

  useEffect(() => {
    fetchEmp();
  }, []);

  const createNewChat = async (id) => {
    try {
      const listResponse = await API.graphql({
        query: listChats,
        variables: {
          filter: {
            senderID: { eq: id },
            status: { eq: "OPEN" },
          },
        },
      });

      //if lenght is zero then the user dont have any chats
      if (listResponse?.data?.listChats?.items.length === 0) {
        const inputData = {
          input: {
            status: "OPEN",
            senderID: id,
            admin: userDataAdmin?.id,
          },
        };
        const res = await API.graphql({
          query: createChat,
          variables: inputData,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        console.log();
        await API.graphql({
          query: createMessage,
          variables: {
            input: {
              chatId: res?.data?.createChat?.id,
              content: "",
              systemContent: `${res?.data?.createChat?.adminDetails?.firstName} ${res?.data?.createChat?.adminDetails?.lastName} has initiated the conversation`,
            },
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        setOpen(false);
        navigate(`/admin-chat/OPEN/${res?.data?.createChat?.id}`);
        updateUserInfo(res?.data?.createChat);
      } else {
        if (listResponse?.data?.listChats?.items[0]?.status === "OPEN") {
          setOpen(false);
          navigate(
            `/admin-chat/OPEN/${listResponse?.data?.listChats?.items[0]?.id}`
          );
        } else {
          const inputData = {
            input: {
              status: "OPEN",
              senderID: id,
              admin: userDataAdmin?.id,
              id: listResponse?.data?.listChats?.items[0]?.id,
            },
          };
          const res = await API.graphql({
            query: updateChat,
            variables: inputData,
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
          setOpen(false);
          navigate(
            `/admin-chat/OPEN/${listResponse?.data?.listChats?.items[0]?.id}`
          );
          updateUserInfo(res?.data?.updateChat);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchModal = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredUsers = userList.filter((user) => {
      const fullName = `${user?.profile?.firstName} ${user?.profile?.lastName}`;
      return fullName.toLowerCase().includes(searchValue);
    });
    setFilteredUserList(filteredUsers);
  };

  useEffect(() => {
    setFilteredUserList(userList);
  }, [userList]);

  return (
    <Modal
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={769}
      className="appointment-modal desired-modal"
      footer={null}
    >
      <div className="imagine-process chat-users-list">
        <div className="text-center">
          <Row justify="center" align="middle">
            <h6 className="header-font">Start New Chat</h6>
          </Row>
        </div>

        <Input
          placeholder="Search User"
          allowClear
          size="large"
          className="bodyarea-search mt-20 search-font"
          onChange={handleSearchModal}
          disabled={initLoading}
        />

        <List
          className="demo-loadmore-list mt-20"
          loading={initLoading}
          itemLayout="vertical"
          dataSource={filteredUserList}
          renderItem={(item) => (
            <List.Item>
              <Skeleton avatar title={false} loading={initLoading} active>
                <div className="w-100">
                  <a
                    href=""
                    className="w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      createNewChat(item.id);
                    }}
                  >
                    <List.Item.Meta
                      avatar={<Avatar src={item?.profile?.profilePhoto} />}
                      title={
                        <span className="p-font">{`${item?.profile?.firstName} ${item?.profile?.lastName}`}</span>
                      }
                    />
                  </a>
                </div>
                <div></div>
              </Skeleton>
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
};

export default EmployeeListModal;
