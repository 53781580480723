import React, { useState, useEffect } from "react";
import logo from "assets/delphy/logo.svg";
import { Layout, Menu, Modal, Typography, Badge, Tabs, Divider } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import Employees from "assets/menu-icons/employees.svg";
import mapIcon from "assets/icons/mapIcon.svg";
import Employers from "assets/menu-icons/employers.svg";
import EobStatus from "assets/menu-icons/eob-status.svg";
import ImagingCenter from "assets/menu-icons/imaging-center.svg";
import SurgeryCenter from "assets/menu-icons/surgery-center.svg";
import InfusionCenter from "assets/menu-icons/infusion-center.svg";
import Doctors from "assets/menu-icons/doctors.svg";
import Labs from "assets/menu-icons/labs.svg";
import PtCenters from "assets/menu-icons/pt-centers.svg";
import TopProcedures from "assets/menu-icons/top-procedures.svg";
import Notification from "assets/menu-icons/notifications.svg";
import Partners from "assets/menu-icons/partners.svg";
import Quicksight from "assets/menu-icons/quicksight.svg";
import Appointment from "assets/menu-icons/appointment.svg";
import Chat from "assets/menu-icons/chat.svg";
import DPC from "assets/menu-icons/dpc.svg";
import { useDispatch, useSelector } from "react-redux";
import ProfileEditRequest from "pages/Approval/ProfileEditRequest";
import moment from "moment";
import newMessageSound from "assets/sounds/Notification.mp3";

import {
  ListDoctorAppointments,
  ListICAppointments,
  ListInfusionAppointments,
  ListPTAppointments,
  ListUserChats,
} from "utility/action";
import { useCallback } from "react";
import { setAppointmentData } from "store/actions/appointment";
import MapView from "./MapViews/MapView";
import { setChatCount } from "store/actions/chatCount";
import { onCreateChat } from "graphql/subscriptions";

export default function AdminLayout({ props }) {
  const { Header, Content, Footer, Sider } = Layout;
  const [editRequestModel, setEditRequestModel] = useState(false);
  const [mapModal, setMapModal] = useState(false);
  const [chatPath, setChatPath] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  const userDataNew = useSelector((state) => state.userReducer?.userData);
  const appCount = useSelector((state) => state.AppointmentReducer?.appData);
  const chatState = useSelector((state) => state.chatReducer?.chatCount);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const name = userDataNew?.firstName;

  const fetchData = useCallback(async () => {
    try {
      const filter = {
        status: { eq: true },
      };
      const docApp = await ListDoctorAppointments(999, filter);
      const icApp = await ListICAppointments(999, filter);
      const ptApp = await ListPTAppointments(999, filter);
      const infusionApp = await ListInfusionAppointments(999, filter);

      const docRes = docApp.map((item) => ({
        ...item,
        isNew: isWithinLast24Hours(item.createdAt),
      }));
      const icRes = icApp.map((item) => ({
        ...item,
        isNew: isWithinLast24Hours(item.createdAt),
      }));
      const ptRes = ptApp.map((item) => ({
        ...item,
        isNew: isWithinLast24Hours(item.createdAt),
      }));
      const infusionRes = infusionApp.map((item) => ({
        ...item,
        isNew: isWithinLast24Hours(item.createdAt),
      }));

      const mergedArray = docRes.concat(icRes, ptRes, infusionRes);
      const unviewedAppointments = mergedArray.filter(
        (row) => row.viewed === false && row.isNew === true
      );
      dispatch(setAppointmentData(unviewedAppointments?.length));
    } catch (error) {
      console.log(error);
    } finally {
    }
  }, []);

  function isWithinLast24Hours(createdAt) {
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    const now = new Date();
    const createdAtDate = new Date(createdAt);
    const diffInMilliseconds = now.getTime() - createdAtDate.getTime();
    return diffInMilliseconds <= oneDayInMilliseconds;
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchUserChats = useCallback(async () => {
    try {
      const response = await ListUserChats(undefined, 999);
      const counts = {
        UNASSIGNED: 0,
        OPEN: 0,
        CLOSED: 0,
      };

      response.forEach((chat) => {
        counts[chat.status]++;
      });

      dispatch(setChatCount(counts));
    } catch (error) {
      console.log(error);
    } finally {
    }
  }, []);

  useEffect(() => {
    fetchUserChats();
  }, [fetchUserChats]);

  const sound = new Audio(newMessageSound);

  useEffect(() => {
    const createMessageSubscriber = API.graphql({
      query: onCreateChat,
      variables: {
        filter: {
          status: { eq: "UNASSIGNED" },
        },
      },
    }).subscribe({
      next: async (d) => {
        const newMsg = d?.value?.data?.onCreateChat;
        if (newMsg) {
          fetchUserChats();
        }
      },
      error: (e) => {
        console.log("Subscription error:", e);
      },
    });

    // Clean up the subscription when the component unmounts
    return () => createMessageSubscriber.unsubscribe();
  }, []);

  const handleLogout = async () => {
    await Auth.signOut({
      global: true,
    })
      .then((data) => {
        localStorage.clear();
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        navigate("/login");
      });
  };

  const handleOk = () => {
    setEditRequestModel(false);
  };

  const handleCancel = () => {
    setEditRequestModel(false);
  };

  const menuItems = [
    {
      label: <span className="button-font fw-600">Welcome, {name}!</span>,
      key: "user",
      className: "userName",
      children: [
        {
          label: (
            <div className="w-100 d-flex" onClick={handleLogout}>
              <span className="logout-btn">Logout</span>
            </div>
          ),
        },
      ],
    },
  ];

  const sidebarItems = [
    {
      label: "Members",
      key: "members",
      icon: Employees,
      link: "/members",
    },
    {
      label: "Employers",
      key: "employers",
      icon: Employers,
      link: "/employers",
    },
    {
      label: "EOB Status",
      key: "eobstatus",
      icon: EobStatus,
      link: "/eobstatus",
    },
    {
      label: "Imaging Centers",
      key: "imaging-center",
      icon: ImagingCenter,
      link: "/imaging-center",
    },
    {
      label: "Surgical Centers",
      key: "surgery-center",
      icon: SurgeryCenter,
      link: "/surgery-center",
    },
    {
      label: "Doctors",
      key: "doctors",
      icon: Doctors,
      link: "/doctors",
    },
    {
      label: "Labs",
      key: "labs",
      icon: Labs,
      link: "/labs",
    },
    {
      label: "Infusion Center",
      key: "infusion-center",
      icon: InfusionCenter,
      link: "/infusion-center",
    },
    {
      label: "Physical Therapy",
      key: "pt-center",
      icon: PtCenters,
      link: "/pt-center",
    },
    {
      label: "Procedures",
      key: "procedures",
      icon: TopProcedures,
      link: "/procedures",
    },
    {
      label: (
        <div>
          Appointments
          {appCount === 0 ? (
            ""
          ) : (
            <Badge
              count={appCount}
              // color="#f5222d"
              style={{
                marginLeft: 15,
              }}
            />
          )}
        </div>
      ),
      key: "appointments",
      icon: Appointment,
      link: "/appointments",
    },
    {
      label: "Promotional Partners",
      key: "partners",
      icon: Partners,
      link: "/partners",
    },
    {
      label: "Notification",
      key: "notifications",
      icon: Notification,
      link: "/notifications",
    },
    {
      label: "QuickSight",
      key: "quicksight",
      icon: Quicksight,
      link: "/quicksight",
    },
    {
      label: "Chat",
      key: "chat",
      icon: Chat,
      // link: "/admin-chat",
      className: "admin-chat-menu",
      submenu: [
        {
          label: (
            <div
              className={`chat-count-badge ${
                chatPath === "unasssigned" ? "chat-category-active" : ""
              }`}
            >
              <Divider type="vertical" className="admin-chat-divider" />
              Unassigned
              <Badge
                count={chatState?.UNASSIGNED}
                style={{
                  marginLeft: 37,
                }}
              />
            </div>
          ),
          key: "unasssigned",
          link: "/admin-chat/UNASSIGNED",
          className: "admin-chat-menu",
        },
        {
          label: (
            <div
              className={`chat-count-badge ${
                chatPath === "open" ? "chat-category-active" : ""
              }`}
            >
              <Divider type="vertical" className="admin-chat-divider" /> Open{" "}
              <Badge
                count={chatState?.OPEN}
                // color="#f5222d"
                style={{
                  marginLeft: 70,
                }}
              />
            </div>
          ),
          key: "open",
          link: "/admin-chat/OPEN",
          className: "admin-chat-menu",
        },
        {
          label: (
            <div
              className={`chat-count-badge ${
                chatPath === "close" ? "chat-category-active" : ""
              }`}
            >
              <Divider type="vertical" className="admin-chat-divider" /> Closed
            </div>
          ),
          key: "close",
          link: "/admin-chat/CLOSED",
          className: "admin-chat-menu",
        },
        // Add more chat rooms as needed
      ],
    },
    {
      label: "Primary Care",
      key: "dpc",
      icon: DPC,
      link: "/dpc",
    },
  ];

  const storedKey = localStorage.getItem("adminSelectedKey");

  useEffect(() => {
    if (storedKey) {
      setSelectedKey(storedKey);
    } else {
      setSelectedKey("members");
    }
  }, [location]);

  const handleMenuItemClick = (data) => {
    setSelectedKey(data?.key);
    localStorage.setItem("adminSelectedKey", data?.key);
  };

  const defaultOpenKeys = sidebarItems
    .filter((item) => item.submenu)
    .map((item) => item.key);

  return (
    <>
      <Layout style={{ height: "100vh" }} className="adminLayout">
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          width="250px"
          className="sidebar"
        >
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="sidebar-wrapper">
            <Menu
              mode="inline"
              selectedKeys={selectedKey}
              defaultOpenKeys={defaultOpenKeys}
              onClick={handleMenuItemClick}
            >
              {sidebarItems.map((item) => {
                if (item.submenu) {
                  return (
                    <Menu.SubMenu
                      key={item.key}
                      icon={
                        <img
                          src={item.icon}
                          alt=""
                          className="menu-item-active"
                        />
                      }
                      title={item.label}
                      style={
                        item.key === "chat" ? { backgroundColor: "#FFF" } : {}
                      }
                    >
                      {item.submenu.map((subItem) => (
                        <Menu.Item key={subItem.key}>
                          <Link
                            to={subItem.link}
                            // onClick={() => setChatPath(subItem?.key)}
                            onClick={() => {
                              setChatPath(subItem?.key);
                            }}
                          >
                            {subItem.label}
                          </Link>
                        </Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  );
                }

                return (
                  <Menu.Item
                    key={item.key}
                    icon={
                      <img
                        src={item.icon}
                        alt=""
                        className="menu-item-active"
                      />
                    }
                  >
                    <Link to={item.link}>{item.label}</Link>
                  </Menu.Item>
                );
              })}
            </Menu>
            <Footer
              style={{
                textAlign: "center",
                color: "#fff !important",
              }}
            >
              Version {process.env.REACT_APP_VERSION} © {moment().year()}{" "}
              <a
                href="https://delphihc.com/"
                target="_blank"
                style={{ color: "inherit" }}
              >
                delphihc.com
              </a>{" "}
              copyright all rights reserved.
            </Footer>
          </div>
        </Sider>
        <Layout>
          <Header className="main-haeder">
            <div className="nav-btn nav-text">
              <span className="nav-map" onClick={() => setMapModal(true)}>
                <img src={mapIcon} alt="" style={{ marginRight: 5 }} /> Map View
              </span>
            </div>
            <div className="nav-btn">
              <Menu mode="horizontal" items={menuItems} />
            </div>
          </Header>
          <Content
            style={{
              margin: "24px 16px 0",
            }}
          >
            <div
              className="site-layout-background"
              style={{
                padding: 24,
                minHeight: 360,
              }}
            >
              {props.children}
            </div>
          </Content>
        </Layout>
      </Layout>

      <Modal
        className="form-modal"
        centered
        width={1000}
        footer={null}
        title={
          <Typography.Title level={3} className="mt-20">
            Profile Edit Requests
          </Typography.Title>
        }
        visible={editRequestModel}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ProfileEditRequest />
      </Modal>

      <Modal
        centered
        className="form-modal"
        visible={mapModal}
        onOk={() => setMapModal(false)}
        onCancel={() => setMapModal(false)}
        width={1000}
        footer={null}
      >
        <div className="appointment-modal">
          <div className="request-appointment">
            <div>
              <h6 style={{ paddingTop: 20 }}>Plot Map</h6>
              <span className="fs-1"></span>
            </div>

            <Tabs className="employer-tabs" defaultActiveKey="1">
              <Tabs.TabPane tab="Doctor" key="1">
                <MapView service="Doctor" />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Imaging Centers" key="2">
                <MapView service="IC" />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Surgical Centers" key="3">
                <MapView service="SC" />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Labs" key="4">
                <MapView service="Labs" />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Physical Centers" key="5">
                <MapView service="PT" />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </Modal>
    </>
  );
}
