import React, { useState, useEffect, useRef } from "react";
import { Col, Divider, Row, Typography } from "antd";
import { API } from "aws-amplify";
import TableSkeleton from "utility/TableSkeleton";

export default function QuicksightAnalytics() {
  const dashboardRef = useRef();
  const [iframeLoaded, setIframeLoaded] = useState(true);
  const [re, setRe] = useState(null);

  const embed = async (id) => {
    try {
      let url = `/getEmbedURL`;
      
      let res = await API.post("bazarhealthREST", url);
      setRe(res);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    if (dashboardRef) {
      embed("2cc563e0-2aa0-4a02-87c2-ea6a02433d66");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Typography.Title level={4} className="text-center mb-0">
        Quicksight
      </Typography.Title>
      <Divider />
      <Row>
        <Col md={24}>
          {iframeLoaded ? <TableSkeleton /> : ""}

          <div className={`${iframeLoaded ? "hide-quicksight" : ""}`}>
            <iframe
            frameBorder="0"
              src={re}
              style={{ height: "80vh", width: "100%" }}
              onLoad={() => setIframeLoaded(false)}
            ></iframe> 
          </div>
        </Col>
      </Row>
    </>
  );
}
